import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { ThemeProvider, Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie/cjs';
import { datadogLogs } from '@datadog/browser-logs';
import { Container } from '@mui/material';
import Script from 'next/script';

import '../styles/globals.css';
import Meta from '../components/core/meta';
import SessionContext from '../contexts/session';
import * as gtag from '../lib/gtag';
import { QUERY_PARAMS } from '../lib/constants';
import { hotjarUserAttribute } from '../lib/hotjar';
import { dataDogUrlErrorWhitelist } from '../utils/data-dog-whitelist';
import { getWebsiteDomain } from '../networking';
import theme_sunnyside_dual_dashboard from '../theme_sunnyside_dual_dashboard';
import theme_sunnyside from '../theme_sunnyside';
import { isOkToLoad } from '../utils/isOkToLoad';
import { ampSetOnce } from '../lib/amplitude';
import { loadPrismicPreviews } from '../utils/prismic/util';
import ErrorBoundary from '../components_sunnyside/error_boundary';
import { brazeSDK_changeUser } from '../lib/braze';
import spacetime from 'spacetime';
import Head from 'next/head';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

interface CustomPageProps { // <--- your custom page props
    // your props
    metaData
}

// used to detect and take care of user load race-condition
export const NOT_LOADED_HINT = 'notloadedonfrontend';

function MyApp({ Component, pageProps }: AppProps<CustomPageProps>): JSX.Element {
    const [user, setUser] = useState({
        id: '',
        email: NOT_LOADED_HINT,
        phone: '',
        givenName: '',
        phoneAuthorized: '',
        gender: '',
        createdAt: '',
        timeZone: '',
        __featureAccess__: [],
        __statesUntyped__: [],
        stripe: {
            currency: ''
        },
        flowType: null,
        diagnosticInFlight: true,
        diagnosticInFlightWithSub: false,
        formId: '',
        hashId: '',
        __challenges__: [],
        __states__: [],
        userCommunityInfo: {
            isCoach: false
        },
        userPaymentEntity: {},
        abtest_bucket: 0,
        create_source: 'unknown',
        __roles__: [],
        diagnostic: {
            focusAreas: '',
            formId: '',
            otherGoals: '',
            topGoal: '',
            targetTotalDrinks: 0,
            targetTotalDryDays: 0,
            totalDrinksBaseline: 0,
            totalDryDaysBaseline: 0,
            id: ''
        }
    });
    const [ui, setUI] = useState({
        hideNav: false,
    });
    const [initialized, setInitialized] = useState(false);
    const initialSession = {
        user,
        setUser,
        initialized,
        ui,
        setUI,
    };

    const router = useRouter();
    // CheckoutType, TrialDays, PremiumOnly
    const { ct, td, po, transaction_id } = router.query;
    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        /**
         * Add UI Session Debug ID
         */
        const sessionCookie = cookies['uiSessionDebugId'] || v4();
        setCookie('uiSessionDebugId', sessionCookie, {
            path: '/',
            domain: getWebsiteDomain()
        });

        const homeUrl = window.location.href.substr(window.location.origin.length);
        gtag.gaPageView(homeUrl);
        /**
         * Google Analytics
         */
        const handleRouteChange = (url) => {
            gtag.gaPageView(url);
            if (typeof window['fbq'] === 'function') {
                window['fbq']('track', 'PageView');
            }
            if (typeof window['ttq'] === 'function') {
                window['ttq'].page();
            }
            gtag.gaObject({ event: 'optimize.route_change' });
        };

        import('../lib/utils').then((util) => {
            const queryParams = util.urlQueryParser(router.asPath);
            const impactClickId = queryParams[QUERY_PARAMS.IMPACT];
            /** check if param exists in url and if it not set before
             * if not set, set the cookie
             */
            if (impactClickId && !cookies[QUERY_PARAMS.IMPACT]) {
                // expires in 30 days
                const expiresAt = new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000));
                setCookie(QUERY_PARAMS.IMPACT, impactClickId, {
                    path: '/',
                    expires: expiresAt,
                    domain: getWebsiteDomain()
                });
            }
        });
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    /**
     * Logs all routes users land on for better debugging
     * Logs all routes for amplitude page views
     */
    useEffect(() => {
        datadogLogs.logger.log(router.asPath, {
            userId: user.id,
            uiSessionDebugId: cookies['uiSessionDebugId']
        });
        if (window !== undefined) {
            if (cookies['amplitude_once_ever'] === 'true') {
                return;
            }

            ampSetOnce('first_page_visited', window.location.href);

            // expires in 1 year, so first_page_visited isn't called again for a year for same browser
            const expiresAt = new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000));
            setCookie('amplitude_once_ever', 'true', {
                path: '/',
                expires: expiresAt,
                domain: getWebsiteDomain()
            });
        }
    }, [router.asPath]);

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        datadogLogs.init({
            clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
            site: 'datadoghq.com',
            forwardErrorsToLogs: true,
            sampleRate: 100,
            env: process.env.NEXT_PUBLIC_NENV,
            service: process.env.NEXT_PUBLIC_DATADOG_SOURCE,
            beforeSend: (log) => {
                if (log.http && log.status === 'error') {
                    const { hostname } = new URL(log.http.url);
                    if (!dataDogUrlErrorWhitelist.some((url) => hostname.includes(url))) {
                        return false;
                    }
                }
            }
        });

        if (window !== undefined && window.location.host.includes('app.') || window.location.host === 'localhost') {
            import('../logic/auth').then((auth) => {
                auth.refreshUserAndStoreIn(initialSession).then(userData => {
                    // TODO - better handling of casting issue
                    // Type error: Type 'unknown' is not assignable to type 'string'.
                    if (!userData.message) {
                        setUser(userData);
                    }
                    setInitialized(true);
                });
            });
        }
    }, []);

    useEffect(()=>{
        if(!router.isReady) return;

        /**
        * Store no CC checkout logic
        */
        if (ct) {
            setCookie('ct', ct, {
                path: '/',
                expires: spacetime().add(1, 'day').toNativeDate(),
                domain: getWebsiteDomain()
            });
        }

        /**
         * Store trial days logic
         */
        if (td) {
            setCookie('td', td, {
                path: '/',
                expires: spacetime().add(1, 'day').toNativeDate(),
                domain: getWebsiteDomain()
            });
        }
        /**
         * Store Premium Only Logic
         */
        if (po) {
            setCookie('po', po, {
                path: '/',
                expires: spacetime().add(1, 'day').toNativeDate(),
                domain: getWebsiteDomain()
            });
        }

        /* Wellput transaction id */
        if (transaction_id) {
            setCookie('_wp_transaction_id', transaction_id, {
                path: '/',
                expires: spacetime.now().add(30, 'day').toNativeDate(),
                domain: getWebsiteDomain()
            });
        }
    }, [router.isReady]);

    /**
     * Hotjar set user for session
     */
    useEffect(() => {
        if (user.id) {
            hotjarUserAttribute(user.id, {
                ui_session_debug_id: cookies['uiSessionDebugId']
            });
            brazeSDK_changeUser(user.id);
        }
    }, [user]);

    /**
     * Different theme is temp for dashboard until full rebuild
     */
    const tempPaths = ['dashboard', 'plan', 'premium-coaching', 'challenge'];
    const theme = tempPaths.includes(router.pathname.split('/')[1]) ? theme_sunnyside_dual_dashboard : theme_sunnyside;

    return (
        <React.Fragment>
            <Meta metaData={pageProps.metaData} />
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Container disableGutters maxWidth={false}>
                    <SessionContext.Provider value={initialSession}>
                        <ErrorBoundary>
                            <Component {...pageProps} />
                        </ErrorBoundary>
                    </SessionContext.Provider>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default MyApp;